@font-face {
  font-family: "SeagoeUIBoldItalic";
  src: local("SeagoeUIBoldItalic"),
    url(./assets/fonts/Segoe\ UI\ Bold\ Italic.ttf) format("truetype");
}

@font-face {
  font-family: "SeagoeUIBold";
  src: local("SeagoeUIBold"),
    url(./assets/fonts/Segoe\ UI\ Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SeagoeUIItalic";
  src: local("SeagoeUIItalic"),
    url(./assets/fonts/Segoe\ UI\ Italic.ttf) format("truetype");
}

@font-face {
  font-family: "SeagoeUI";
  src: local("SeagoeUI"), url(./assets/fonts/Segoe\ UI.ttf) format("truetype");
}

* {
  font-family: "SeagoeUI";
}

p {
  margin: 0;
}

body {
  margin: 0;
  font-family: "SeagoeUI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "SegoeUI";
}
